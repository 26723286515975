import { Company } from "./company.model";
import { Address, Weekday, Reference, Tool, User, TradeExperience, WorkerStatus } from "./constant.objects";
import { Skill } from './request.model';

export class Worker {
  id: number;
  confirmed: boolean;
  worker_trade_experiences: Array<TradeExperience>;
  address: Address;
  weekdays: Array<Weekday>;
  tools: Array<Tool>;
  worker_tools: Array<any>;
  user: User;
  references: Array<Reference>;
  worker_status: WorkerStatus;
  notes: string;
  rating: number;
  rating_percentage: number;
  date_signed_up: Date;
  date_of_birth: Date;
  email: string;
  phone_number: string;
  location_radius: number;
  last_name: string;
  first_name: string;
  profile_picture_link: string;
  facebook_id: string;
  worker_web_app: string;
  worker_ios_app_version: string;
  worker_android_app_version: string;
  referred_by: any;
  geolocation: any;
  bio: string;
  terms: boolean;
  terms_accepted: any[] = [];
  distance: number;
  user_role: { id: number; description: string };
  confirmation_code: number;
  blacklisted_companies?: BlackListedCompanies[];
  referral_code: string;
  worker_quality_score: number;
  address_country: string;
  work_permits: WorkPermit[];
  w2_verified_at: string;
  worker_location_status: any;
  most_recent_location: any;
  recruits?: Recruit[];
  payroll?: Payroll[];
  gamify_points: number;
  gamify_level: any;
  last_login_at: string;
  blacklists: Blacklist[];
  score: number;
  organizations?: any[];
  organization_affiliations?: any[];

  constructor(
    id: number,
    confirmed: boolean,
    trade_experiences: Array<TradeExperience>,
    address: Address,
    weekdays: Array<Weekday>,
    tools: Array<Tool>,
    user: User,
    references: Array<Reference>,
    worker_status: WorkerStatus,
    notes: string,
    date_signed_up: Date,
    date_of_birth: Date,
    referral_code: string = null
  ) {
    this.id = id;
    this.confirmed = confirmed;
    this.worker_trade_experiences = trade_experiences;
    this.address = address;
    this.weekdays = weekdays;
    this.tools = tools;
    this.user = user;
    this.references = references;
    this.worker_status = worker_status;
    this.notes = notes;
    this.date_signed_up = date_signed_up;
    this.date_of_birth = date_of_birth;
    this.referral_code = referral_code;
  }
}

export interface Blacklist {
  expires_at: string;
  created_at: string;
  id: number;
  user_id: number;
  notes: string;
}
export interface Payroll {
  id: number;
  company_id: number;
  user_id: number;
  company: Company;
}

export interface Recruit {
  active?: boolean;
  author_id?: number;
  code_hour_threshold_id?: number;
  code_recruit_status_id?: number;
  company: Company;
  created_at?: string;
  deleted_at?: string;
  hours_completed?: string;
  id?: number;
  recruited?: boolean;
  threshold?: string;
  updated_at?: string;
  user_id?: number;
}
export interface WorkerStatistics {
  points: Point;
  earnings: Statistic;
  hours: number;
  completed_requests: number;
  projects: number;
  no_shows: number;
  cancellations: number;
  worker_quality_score: WorkerQualityScore;
  withdraw: number;
  late: number;
  late_withdraws: number;
  score: number;
}

interface Point {
  total: number;
  week: number;
  totalOfLevel: number;
}
interface Statistic {
  total: number;
  week: number
}

export interface WorkerQualityScore {
  user_id: number,
  score: number,
  total_hours: string,
  total_hours_percentile: string,
  total_hours_time_period: string,
  total_hours_time_period_percentile: string,
  total_hours_time_period_days: number,
  total_extensions: number,
  total_extensions_percentile: string,
  total_extensions_time_period: number,
  total_extensions_time_period_percentile: string,
  total_extensions_time_period_days: number,
  total_positive_ratings: number,
  total_positive_ratings_percentile: string,
  total_positive_ratings_time_period: number,
  total_positive_ratings_time_period_percentile: string,
  total_positive_ratings_time_period_days: number,
  total_negative_ratings: number,
  total_negative_ratings_percentile: string,
  total_negative_ratings_time_period: number,
  total_negative_ratings_time_period_percentile: string,
  total_negative_ratings_time_period_days: number,
  total_cancellations: number,
  total_cancellations_percentile: string,
  total_cancellations_time_period: number,
  total_cancellations_time_period_percentile: string,
  total_cancellations_time_period_days: number,
  total_no_shows: number,
  total_no_shows_percentile: string,
  total_no_shows_time_period: number,
  total_no_shows_time_period_percentile: string,
  total_no_shows_time_period_days: number
}

export interface BlackListedCompanies {
  id: number;
  name: string;
  pivot: Pivot;
}

interface Pivot {
  user_id: number;
  company_id: number;
  created_at: string;
  updated_at: string;
}

export interface WorkerAddress {
  address: Address;
  location_radius: number;
}

export interface WorkPermit {
  created_at?: string;
  deleted_at?: string;
  expiry_date?: string;
  id: number;
  permit_number?: string;
  user_id: number;
  permit_image?: string;
  code_w9_status_id: number;
  w9_status: W9Status;
}

export interface WorkerDocs {
  sin: string;
  work_permits: WorkPermit[];
}

export class WorkPermitImage {
  constructor(public src: string, public file: File) {}
}

export interface W9Status {
  id: number;
  description: string;
  es_description: string;
}

export interface WorkerEndorsemnt {
  endorsements_count: number;
  endorsers: EndorserSegment[];
}

export interface EndorserSegment {
  actioned_by_user_id: number;
  author_id: number;
  endorser: Endorser;
  hours: string | number;
  id: number;
  project_id: number;
  request_id: number;
  request_skill_id: number;
  skill: Skill;
  skill_id: number;
  user_id: number;
}

export interface Endorser {
  title: string;
  company: any;
  id: number;
  profile_picture_link: string;
  first_name: string;
  last_name: string;
}

export interface WorkerTradeSkills {
  worker_skill_logs: WorkerSkillLog[];
  worker_skill_unacquired: Skill[];
  worker_trade_experience: TradeExperience;
}

export interface WorkerSkillLog {
  skill: Skill;
  actioned_by_user_id: number;
  endorsed_count: number;
  id: number;
  skill_id: number;
  user_id: number;
}
