// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiURL: "https://staging.faberconnect.com",
  pusher: {
    key: 'f046b7ce3e9773430fa5',
    cluster: 'us3'
  },
  stripe_publishable_key_ca: 'pk_test_HIK07thV9P8ojo7HShUyAOTb',
  stripe_publishable_key_us: 'pk_test_5nwLYrfo3FBNJ9SFxLTAM9SX005cEV4Axq',
};
