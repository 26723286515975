import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';

import { ApiClient } from './api-client';
import {Router} from "@angular/router";
import {ContractorPaginated} from "../../models/contractor-paginated.model";
import {Contractor} from "../../models/contractor.model";
import { WorkerPaginated } from '../../models/worker-paginated.model';

@Injectable()
export class ContractorService {
  contractorApiUrl = '/admin/contractor';
  userApiUrl = '/admin/user';
  contractorsPaginated: ContractorPaginated;
  workersPaginated: WorkerPaginated;

  constructor(public apiClient: ApiClient, public router: Router) {
  }

  getContractor(id: string): Observable<Contractor> {
      return this.apiClient.get(this.contractorApiUrl + '/' + id);
  }
  getTimelineEvents(id):any {

  }

  updateContractor(contractor:Contractor):Observable<Contractor> {
    return this.apiClient.patch(`${this.contractorApiUrl}/${contractor.id}`, contractor)
  }

  getIntercomUser(contractor_id: number): Observable<any> {
      return this.apiClient.get(`${this.userApiUrl}/${contractor_id}/intercom`);
  }


  banUser(id:number):Observable<Worker> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/ban`, null)
  }


  getContractors(params:any): Observable<ContractorPaginated> {

    params = params || {}
    if(!("page" in params))
      params.page = 1;

    let urlparams = Object.keys(params)
    .filter(k=>params[k]!=null && String(params[k]).length>0)
    .map(k=>{
      return k + "=" + encodeURIComponent(params[k]);
    }).join("&")

    console.log(urlparams);

    return this.apiClient.get(`${this.contractorApiUrl}?${urlparams}`)
      .do(res=>this.contractorsPaginated=res);
  }



  getCurrentWorkers(contractor_id: number, params: any): Observable<WorkerPaginated> {
    params = params || {}
    if (!("page" in params))
      params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k]
      }).join("&")

    return this.apiClient.get(`${this.contractorApiUrl}/${contractor_id}/current-workers?${urlparams}`)
      .do(res => this.workersPaginated = res);
  }

  getContractorSignUp(start_date: string, end_date: string, params: any): Observable<ContractorPaginated> {

    params = params || {}
    if(!("page" in params))
      params.page = 1;

    let urlparams = Object.keys(params)
    .filter(k=>params[k]!=null && String(params[k]).length>0)
    .map(k=>{
      return k + "=" + params[k]
    }).join("&")

    console.log(urlparams);

    return this.apiClient.get(`${this.contractorApiUrl}?start_date=${start_date}&end_date=${end_date}&${urlparams}`)
  }


}
