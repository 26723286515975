import { ApiClient } from './api-client';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpHeaders} from '@angular/common/http';


@Injectable()
export class PaymentService{

    private paymentsApiUrl = '/admin/payments';

    constructor(private apiClient : ApiClient){}

    downloadFile(week, params: { markPaid: boolean, payRoll: boolean}): Observable<Blob> {
        let markPaid;
        params.markPaid ? markPaid = 1 : markPaid = 0;
        const options = {responseType: 'blob'};
        const headers = new HttpHeaders()
        headers.append('content-type', 'application/csv');
        return this.apiClient.get(`${this.paymentsApiUrl}/export-worker-weekly-pay-csv?week=${week}&mark_paid=${markPaid}${ params.payRoll ? `&payroll_strict=${params.payRoll ? 1 : 0}` : ''}`, options, headers);
    }


}

