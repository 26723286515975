import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ApiClient } from './api-client';
import { Router } from "@angular/router";

@Injectable()
export class ExportService {

  exportApiUrl = '/admin/export/excel';

  constructor(private apiClient: ApiClient) { }

  exportAnalytics(exportType: any, start_date: string, end_date: string) {
  	return this.apiClient.get(`${this.exportApiUrl}/${exportType}?start_date=${start_date}&end_date=${end_date}`);
  }
}
