import { ApiClient } from './api-client';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ImageService {
  userApiUrl = "/admin/user"
  companyApiUrl = "/admin/company"
  constructor(private apiClient : ApiClient){}

  selectImage(): Promise<string> {

      return new Promise<string>((res,rej)=>{
        let fileElm = <HTMLInputElement> document.createElement("input")
        fileElm.type = "file"
        fileElm.multiple = false
        fileElm.onchange = ()=>{
          if(fileElm.files.length>0)
          {
            console.log("reading file")
            let fr = new FileReader()

            fr.onload = ()=>res(btoa(fr.result as string))
            fr.onerror = ()=>rej()
            fr.onabort = ()=>rej()

            fr.readAsBinaryString(fileElm.files[0])

          }
          else
            rej()
          }
        fileElm.click()
      })
  }

  uploadProfilePic(user_id:number,image:any):Observable<any> {
    return this.apiClient.post(`${this.userApiUrl}/${user_id}/profile-picture`,{image})
  }


  uploadCompanyLogo(company_id: number, image: any): Observable<any> {
    return this.apiClient.post(`${this.companyApiUrl}/${company_id}/logo`, { image })
  }

}
