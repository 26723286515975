import { Pipe, PipeTransform, Injectable } from "@angular/core";
//import { Job } from "../models/job";
import { Request } from '../../models/request.model';
import * as moment from "moment";

@Injectable()
@Pipe({ name: "days_since_start" })
export class DaysSinceStartPipe implements PipeTransform {
  transform(request: Request): number {
    if (request) {  
      let startDateBeforeToday = moment().diff(
        moment(`${request.start_date}`),
        "days"
      );

      const startTime = request.start_date + 'T' + request.start_time + 'Z';
      const startTimeBeforeNow = moment().diff(
        moment(`${startTime}`),
        "minutes"
      );

      //if the start time is in the future
      if (startTimeBeforeNow < 0 ) {
        return 0;
      }

      //diff operator is like minus (future - today < 0 than today is larger[more recent])
      const endDateMinusToday = moment(`${request.end_date}`).diff(moment(), "days");

      //if today is after the end_date
      if (endDateMinusToday < 0) {
        return (
          Math.abs(
            moment(`${request.end_date}`).diff(moment(`${request.start_date}`), "days")
          ) + 1
        );
      } else {
        return startDateBeforeToday + 1;
      }
    }
  }
}