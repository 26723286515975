import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";
import { of } from "rxjs/observable/of";

import { ApiClient } from "./api-client";
import { BreakTime, City } from "../../models/constant.objects";
import { ConstantModal, ConstantModalInterface } from "../../models/constant.objects";
import { Experience } from "../../models/constant.objects";
import { MatchStatus } from "../../models/constant.objects";
import { ProjectStatus } from "../../models/constant.objects";
import { Tool, ToolsParams } from "../../models/constant.objects";
import { Trade } from "../../models/constant.objects";
import { UserRole } from "../../models/constant.objects";
import { Weekday } from "../../models/constant.objects";
import { WorkerStatus } from "../../models/constant.objects";
import { RequestStatus } from "../../models/constant.objects";
import { RequestSource } from "../../models/constant.objects";
import { TradeExperience as TradeExperience2 } from "../../models/tradeExperience.model";
import { Term } from "../../models/constant.objects";
import { ReferralSource } from "../../models/constant.objects";
import { Province } from "../../models/constant.objects";
import { Country } from "../../models/constant.objects";
import { Overtime } from "../../models/constant.objects";
import { HourStatus } from "../../models/constant.objects";
import { TierStatus } from "../../models/constant.objects";
import { Tags } from "../../models/project.model";
import { SkillTag } from "../../models/request.model";
import { map } from "rxjs/operators";

@Injectable()
export class ConstantsService {
  version2ApiUrl = '/v2';
  constantsApiUrl = "/c";
  workerApiUrl = "/w";

  breakTimes: BreakTime[];
  eventTypyes: Array<any>;
  completeEventTypes: Array<any>;
  overtimes: Overtime[];
  experiences: Experience[];
  matchStatus: MatchStatus[];
  projectStatus: ProjectStatus[];
  tools: Tool[];
  term: Term;
  trades: Trade[];
  worker_trades: Trade[];
  userRoles: UserRole[];
  weekdays: Weekday[];
  workerStatus: WorkerStatus[];
  requestStatus: RequestStatus[];
  requestSource: RequestSource[];
  referralSources: ReferralSource[];
  referralWorkerSources: ReferralSource[];

  provinces: Province[];
  countries: Country[];
  cities: City[];
  hour_status: HourStatus[];
  tier_status: TierStatus[];

  constructor(public apiClient: ApiClient) { }

  getModalObjects(object: ConstantModalInterface): Observable<any[]> {
    return this.apiClient.get(`${this.constantsApiUrl}${object.getAPIPath()}`);
  }

  editModalObject(data: any, object: ConstantModalInterface): Observable<any> {
    return this.apiClient.patch(`${this.constantsApiUrl}${object.getAPIPath()}`, data);
  }

  addModalObject(data: any, object: ConstantModalInterface): Observable<any> {
    return this.apiClient.post(`${this.constantsApiUrl}${object.getAPIPath()}`, data);
  }

  getBreakTimes(): Observable<BreakTime[]> {
    if (this.breakTimes) return Observable.of(this.breakTimes);
    return this.apiClient.get(this.constantsApiUrl + "/break-times").map(res => {
      let breakTimes = <BreakTime[]>res;
      this.breakTimes = breakTimes;
      console.log(breakTimes);
      return this.breakTimes;
    });
  }

  getOvertimes(): Observable<Overtime[]> {
    if (this.overtimes) return Observable.of(this.overtimes);
    return this.apiClient.get(this.constantsApiUrl + "/overtimes").map(res => {
      let overtimes = <Overtime[]>res;
      this.overtimes = overtimes;
      console.log(overtimes);
      return this.overtimes;
    });
  }

  getExperiences(): Observable<Experience[]> {
    if (this.experiences) return Observable.of(this.experiences);
    return this.apiClient.get(this.constantsApiUrl + "/experiences").map(res => {
      let experiences = <Experience[]>res;
      this.experiences = experiences;
      console.log(experiences);
      return this.experiences;
    });
  }

  getTimelineEvents(): Observable<Array<any>> {
    if (this.eventTypyes) return Observable.of(this.eventTypyes);
    return this.apiClient.get(this.constantsApiUrl + "/timeline-event-types").do(res => (this.eventTypyes = res));
  }

  getCompleteTimelineEvents(): Observable<Array<any>> {
    if (this.completeEventTypes) return Observable.of(this.completeEventTypes);
    return this.apiClient
      .get(this.constantsApiUrl + "/complete-timeline-event-types")
      .do(res => (this.completeEventTypes = res));
  }

  getTradeExperiences(tradeId, projectId, workervisible?: boolean): Observable<TradeExperience2[]> {
    console.log("Worker visibility ", workervisible);
    let visibility = workervisible == true ? "worker_visible" : "admin_visible";
    return this.apiClient.get(`/v2/c/trades/${tradeId}/experiences` + `?${visibility}=1` + (!workervisible ? `&project_id=${projectId}` : '')).map(res => {
      let tradeExperiences = <TradeExperience2[]>res;
      console.log(tradeExperiences);
      return tradeExperiences;
    });
  }

  getMatchStatus(): Observable<MatchStatus[]> {
    if (this.matchStatus) return Observable.of(this.matchStatus);
    return this.apiClient.get(this.constantsApiUrl + "/match-status").map(res => {
      let matchStatus = <MatchStatus[]>res;
      this.matchStatus = matchStatus;
      console.log(matchStatus);
      return this.matchStatus;
    });
  }

  getProjectStatus(): Observable<ProjectStatus[]> {
    if (this.projectStatus) return Observable.of(this.projectStatus);
    return this.apiClient.get(this.constantsApiUrl + "/project-status").map(res => {
      let projectStatus = <ProjectStatus[]>res;
      this.projectStatus = projectStatus;
      console.log(projectStatus);
      return this.projectStatus;
    });
  }

  getTools(params?: ToolsParams): Observable<Tool[]> {
    const urlParams = params ? "?" + this.prepareUrlParams(params) : "";
    return this.apiClient.get(`${this.constantsApiUrl}/tools${urlParams}`);
  }

  getTrades(): Observable<Trade[]> {
    if (this.trades) return Observable.of(this.trades);
    return this.apiClient.get('/v2' + this.constantsApiUrl + "/trades" + "?country_id=1").map(res => {
      let trades = <Trade[]>(res.result.trades);
      this.trades = trades;
      console.log(trades);
      return this.trades;
    });
  }

  getWorkerTrades(): Observable<Trade[]> {
    if (this.worker_trades) return Observable.of(this.worker_trades);
    return this.apiClient.get('/v2' + this.constantsApiUrl + "/trades?admin_visible=1" + "&country_id=1").map(res => {
      let trades = <Trade[]>(res.result.trades);
      this.worker_trades = trades;
      console.log(trades);
      return this.worker_trades;
    });
  }

  getUserRoles(): Observable<UserRole[]> {
    if (this.userRoles) return Observable.of(this.userRoles);
    return this.apiClient.get(this.constantsApiUrl + "/user-roles").map(res => {
      let userRoles = <UserRole[]>res;
      this.userRoles = userRoles;
      console.log(userRoles);
      return this.userRoles;
    });
  }

  getWeekdays(): Observable<Weekday[]> {
    if (this.weekdays) return Observable.of(this.weekdays);
    return this.apiClient.get(this.constantsApiUrl + "/weekdays").map(res => {
      let weekdays = <Weekday[]>res;
      this.weekdays = weekdays;
      console.log(weekdays);
      return this.weekdays;
    });
  }

  getReferralSources(): Observable<ReferralSource[]> {
    if (this.referralSources) return Observable.of(this.referralSources);
    return this.apiClient.get(this.constantsApiUrl + "/referral-sources?contractor_visible=1").map(res => {
      let referralSources = <ReferralSource[]>res;
      this.referralSources = referralSources;
      console.log(referralSources);
      return this.referralSources;
    });
  }

  getWorkerReferralSources(): Observable<ReferralSource[]> {
    if (this.referralSources) return Observable.of(this.referralSources);
    return this.apiClient.get(this.constantsApiUrl + "/referral-sources?worker_visible=1").map(
      res => (this.referralWorkerSources = res)

      // {
      //   let referralSources = <ReferralSource[]>res;
      //   this.referralWorkerSources = referralSources;
      //   console.log(referralWorkerSources);
      //   return this.referralWorkerSources;
      // }
    );
  }

  getWorkerStatus(): Observable<WorkerStatus[]> {
    if (this.workerStatus) return Observable.of(this.workerStatus);
    return this.apiClient.get(this.constantsApiUrl + "/worker-status").map(res => {
      let workerStatus = <WorkerStatus[]>res;
      this.workerStatus = workerStatus;
      console.log(workerStatus);
      return this.workerStatus;
    });
  }

  getRequestStatus(): Observable<RequestStatus[]> {
    if (this.requestStatus) return Observable.of(this.requestStatus);
    return this.apiClient.get(this.constantsApiUrl + "/request-status").map(res => {
      let requestStatus = <RequestStatus[]>res;
      this.requestStatus = requestStatus;
      console.log(requestStatus);
      return this.requestStatus;
    });
  }

  getRequestSource(): Observable<RequestSource[]> {
    if (this.requestSource) return Observable.of(this.requestSource);
    return this.apiClient.get(this.constantsApiUrl + "/request-sources").map(res => {
      let requestSource = <RequestSource[]>res;
      this.requestSource = requestSource;
      console.log(requestSource);
      return this.requestSource;
    });
  }

  getContractorTerms(): Observable<Term> {
    return this.apiClient.get(this.constantsApiUrl + "/contractor-terms").map(res => {
      let term = <Term>res;
      this.term = term;
      console.log(term);
      return this.term;
    });
  }

  getProvinces(): Observable<Province[]> {
    if (this.provinces) return Observable.of(this.provinces);
    return this.apiClient.get(this.constantsApiUrl + "/provinces").map(res => {
      let provinces = <Province[]>res;
      this.provinces = provinces;
      console.log(provinces);
      return this.provinces;
    });
  }

  getCountries(): Observable<Country[]> {
    if (this.countries) return Observable.of(this.countries);
    return this.apiClient.get(this.constantsApiUrl + "/countries").map(res => {
      let countries = <Country[]>res;
      this.countries = countries;
      console.log(countries);
      return this.countries;
    });
  }

  getHourStatus(): Observable<HourStatus[]> {
    if (this.hour_status) return Observable.of(this.hour_status);
    return this.apiClient.get(this.constantsApiUrl + "/hour-status").map(res => {
      let hour_status = <Country[]>res;
      this.hour_status = hour_status;
      console.log(hour_status);
      return this.hour_status;
    });
  }

  getCities(): Observable<City[]> {
    if (this.cities) return Observable.of(this.cities);
    return this.apiClient.get(this.constantsApiUrl + "/cities").map(res => {
      let cities = <City[]>res;
      this.cities = cities;
      return this.cities;
    });
  }

  getCity(city_id): Observable<City> {
    return this.apiClient.get(this.constantsApiUrl + "/cities/" + city_id).map(res => {
      let city = <City>res;
      return city;
    });
  }

  getTierStatus(): Observable<TierStatus> {
    return this.apiClient.get(this.constantsApiUrl + "/tier_status").map(res => {
      let tier_status = res;
      this.tier_status = tier_status;
      console.log(tier_status);
      return this.tier_status;
    });
  }

  getProjectTags(): Observable<Tags[]> {
    return this.apiClient.get(this.constantsApiUrl + "/project-tags").map(res => res || {});
  }

  getProjectPhases(): Observable<Tags[]> {
    return this.apiClient.get(this.constantsApiUrl + "/project-phases").map(res => res || {});
  }

  getTradeSkillTags(trade_id: number): Observable<SkillTag[]> {
    return this.apiClient.get(`${this.constantsApiUrl}/trades/${trade_id}/skill-tags`);
  }

  getProjectInsurancePolicies(): Observable<Tags[]> {
    return this.apiClient.get(this.constantsApiUrl + "/insurance-policy-types").map(res => res || {});
  }

  getWorkerRatingTags(): Observable<Tags[]> {
    return this.apiClient.get(this.constantsApiUrl + "/worker_tags").map(res => res || {});
  }

  getFirstnationOrganizations(): Observable<any> {
    return this.apiClient.get(this.version2ApiUrl + this.constantsApiUrl + '/organizations' + '?code_organization_type_id=2').map(res => { return res; });
  }

  getFirstnationSubOrganizationTypes(): Observable<any> {
    return this.apiClient.get(this.version2ApiUrl + this.constantsApiUrl + '/organizations' + '?code_organization_type_id=1').map(res => { return res; });
  }

  /// Helper functions
  private prepareUrlParams(params: any): string {
    return Object.keys(params)
      .filter(k => params[k] !== null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
  }
}
