import { Injectable, Inject, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/finally";
import "rxjs/add/observable/of";
import "rxjs/add/observable/onErrorResumeNext";
import { Router } from "@angular/router";
import { AlertDialog } from "../components/alert-dialog/alert-dialog";
import { ApiClient } from "./api-client";
import { tap } from "rxjs/operators";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  apiService: any;

  constructor(public router: Router, public alert: AlertDialog, private inj: Injector) {
    // use injector and sitTimeout to solve cyclic injection and maximum stack injection errors
    setTimeout(() => {
      this.apiService = this.inj.get(ApiClient);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changedRequest;
    let city_id = localStorage.getItem("city_id");
    if (request.method == "GET" && city_id) {
      let params = { city_id: city_id };
      changedRequest = request.clone({ setParams: params });
    } else {
      changedRequest = request;
    }

    return next.handle(changedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // if the response has the refreshed token update the token var and return the request handle with the new token
            const refreshedToken = event.headers.get("Authorization");
            if (refreshedToken) {
              // se the var to true so concurrent request that fails with 401 falls to the addAuthenticationToken function
              this.refreshTokenInProgress = true;

              // removes the 'Bearer ' part from the token before save it
              this.apiService.setToken(refreshedToken.replace("Bearer ", ""));

              return next.handle(this.addAuthenticationToken(request));
            }
          }
        },
        (err: any) => {
          console.log(err);
          
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400 && err.error.error == "token_not_provided") {
              // logout
              this.redirectToLogin();
            } else if (err.status === 400 && err.error.error == "token_invalid") {
              // logout
              this.redirectToLogin();
            } else if (err.status === 400) {
              // Show error alert
              let message = "";
              let error = err.error || err.message;
              if (typeof error === "string") {
                message = error;
                this.alert.show({ title: "Unable to process request", message: message });
              } else {
                message = Object.keys(error)
                  .map((k) =>{
                    if (error[k]) { 
                      return error[k].toString();
                    }
                  }).filter( message => typeof message ==='string')
                  .join(": ");
                this.alert.show({ title: "Invalid Input", message: message });
              }
            }
            // If user is not authenticated or token has expired
            else if (err.status === 401) {
              // if not login route
              if (!err.url.endsWith("auth/contractor")) {
                // if the refresh token is on process reset the var and handle the request with header token
                if (this.refreshTokenInProgress) {
                  this.refreshTokenInProgress = false;
                  return next.handle(this.addAuthenticationToken(request));
                } else {
                  // logout
                  this.redirectToLogin();
                }
              } else {
                // on auth attempt just show user the fail login msg
              }
            } else if(err.status == 403) {
              const { message } = err.error;
              this.alert.show({ title: "Recruit failed", message: message[0] });
            }
            
            else if (err.status == 0) {
              this.alert.show({ title: "Network Error", message: "Make sure you are connected to the internet" });
            }
          }
        }
      )
    );
  }

  redirectToLogin() {
    localStorage.removeItem("token");
    console.log("removing token");
    this.router.navigate(["/login"], { queryParams: { d: this.router.url }, queryParamsHandling: "preserve" });
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.apiService.token;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: accessToken
      }
    });
  }
}
