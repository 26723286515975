import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';

import { ApiClient } from './api-client';
import {Router} from "@angular/router";
import {QuotePaginated} from "../../models/quote-paginated.model";
import {Quote} from "../../models/quote";
@Injectable()
export class QuoteService {
  quoteApiUrl = '/admin/quote';
  quotesApiUrl = '/admin/quotes';
  quotesPaginated: QuotePaginated;

  constructor(public apiClient: ApiClient, public router: Router) {
  }


  getQuotes(page: number = 1): Observable<QuotePaginated> {
      let url = this.quoteApiUrl + "?page=" + page;

      return this.apiClient.get(url)
          .map((res: any) => {
              this.quotesPaginated = <QuotePaginated>(res);
              return this.quotesPaginated;
          });
  }


  editQuoteNote(quote:Quote) {
    return this.apiClient.patch(`${this.quotesApiUrl}/${quote.id}`,quote)
  }

}
