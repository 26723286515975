import { ApiClient } from "./api-client";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { ReferralPaginated } from "../../models/referral.model";

@Injectable()
export class ReferralService {
  private referralApiUrl = "/admin/user/referral";

  constructor(private apiClient: ApiClient) {}

  getReferrals(params): Observable<ReferralPaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] !== null && params[k].toString().length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(this.referralApiUrl + "/?" + urlparams);
  }

  togglePaidStatus(referralSourceId: number, paidStatus: any): Observable<any> {
    return this.apiClient.patch(this.referralApiUrl + '/' + referralSourceId, paidStatus);
  }
}
