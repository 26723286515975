import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertDialogComponent } from "./alert-dialog.component"

@Injectable()
export class AlertDialog  {
 options:{title?:string, message?:string,backdropDismiss?:boolean,buttons?:any[], subtitle?:string}
 constructor(private modalService:BsModalService) {
 }

 show(opts:{title?:string, message?:string,backdropDismiss?:boolean,buttons?:any[], subtitle?: string}):Promise<number>{

   opts = opts || <any>{}
   if(opts.backdropDismiss!==false)
     opts.backdropDismiss =  true
   let modal = this.modalService.show(AlertDialogComponent,{ignoreBackdropClick:!opts.backdropDismiss,show:false})
   let comp = modal.content as AlertDialogComponent
   comp.title = opts.title
   comp.message = opts.message
   comp.buttons = opts.buttons
   comp.subtitle = opts.subtitle
   comp.init()
   return new Promise((res,rej)=>{

     comp.onDismiss.take(1)
     .do(v=>res(v),error=>rej(error))
     .do(v=>modal.hide())
     .subscribe()
     this.modalService.onHidden.subscribe(t=>res())
   })

 }

}
