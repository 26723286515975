import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiClient {
  token: string;

  BASE_URL = environment.apiURL;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
    localStorage.setItem("token", token);
  }

  createAuthorizationHeader(headers: HttpHeaders) {
    return headers.append("Authorization", "Bearer " + this.token);
  }

  createContentTypeHeader(headers: HttpHeaders) {
    return headers.append("content-type", "application/json");
  }

  get(url, options: any = {}, headers: HttpHeaders = new HttpHeaders()): any {
    options.headers = headers;
    this.token = localStorage.getItem("token");
    options.headers = this.createAuthorizationHeader(options.headers);

    return this.http.get<any>(this.BASE_URL + url, options);
  }

  post(url, data, options: any = {}): any {
    options.headers = new HttpHeaders();
    this.token = localStorage.getItem("token");
    options.headers = this.createAuthorizationHeader(options.headers);
    return this.http.post<any>(this.BASE_URL + url, data, options);
  }

  patch(url, data) {
    let headers = new HttpHeaders();
    this.token = localStorage.getItem("token");
    headers = this.createAuthorizationHeader(headers);
    headers = this.createContentTypeHeader(headers);
    return this.http.patch<any>(this.BASE_URL + url, data, {
      headers: headers
    });
  }

  delete(url) {
    let headers = new HttpHeaders();
    this.token = localStorage.getItem("token");
    headers = this.createAuthorizationHeader(headers);
    headers = this.createContentTypeHeader(headers);
    return this.http.delete<any>(this.BASE_URL + url, {
      headers: headers
    });
  }
}
