import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import {City} from "../../models/constant.objects";
import {ConstantsService} from "./constants.service";
import { Observable } from 'rxjs/Rx';

@Injectable()
export class CityService {

    city: City;

    constructor(public constantsService: ConstantsService) {
    }

    get(): Observable<City>  {
        let city_id = localStorage.getItem('city_id');
        if(!city_id) {
            return Observable.of(this.city)
        }
        return this.constantsService.getCity(city_id).map(city => {
            this.city = city;
            return this.city;
        });
    }

    set(city: City) {
        localStorage.setItem('city_id', String(city.id));
        this.city = city;
        window.location.reload();
    }

    setById(city_id: number) {
        localStorage.setItem('city_id', String(city_id));
        window.location.reload();
    }

}
