import { ApiClient } from "./api-client";
import { MapStats } from "../../models/map-stats";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import {
    ContractorEngagementStats,
    ContractorOnboardingStats,
    ContractorSourceStats,
    FeatureStats,
    HistoricalStats,
    HistoricalStatsParams,
    MonthlyMetrics,
    OfferEngagementStats,
    HoursLoggedStats,
    WorkerEngagementStats,
    WorkerOnboardingStats,
    WorkerSourceStats,
    WorkerStatusStats,
    WorkerTradesStats
} from "../../models/statistic.models";

@Injectable()
export class StatsService {
  private statsApiUrl = "/admin/statistics";
  private metricsApiUrl = "/admin/metrics";

  constructor(private apiClient: ApiClient) {}

  getContractorStats(params = {}): Observable<ContractorSourceStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/contractor?${urlparams}`);
  }

  getWorkerStats(params = {}): Observable<WorkerSourceStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/worker?${urlparams}`);
  }

  getWorkerEngagementStats(params = {}): Observable<WorkerEngagementStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/worker-engagement?${urlparams}`);
  }

  getOfferEngagementStats(params = {}): Observable<OfferEngagementStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/offer-engagement?${urlparams}`);
  }

  getHoursLoggedStats(params = {}): Observable<HoursLoggedStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/hours?${urlparams}`)
  }

  getWorkerOnboardingStats(params = {}): Observable<WorkerOnboardingStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/worker-onboarding?${urlparams}`);
  }

  getWorkerStatusStats(params = {}): Observable<WorkerStatusStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/worker-status?${urlparams}`);
  }

  getWorkerTradesStats(params = {}): Observable<WorkerTradesStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/worker-trades?${urlparams}`);
  }

  getWorkerMapStats(params = {}): Observable<MapStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/workers-map?${urlparams}`);
  }

  getRequestMapStats(params = {}): Observable<MapStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/requests-map?${urlparams}`);
  }

  getContractorOnboardingStats(params = {}): Observable<ContractorOnboardingStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/contractor-onboarding?${urlparams}`);
  }

  getContractorEngagementStats(params = {}): Observable<ContractorEngagementStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/contractor-engagement?${urlparams}`);
  }

  getFeatureStats(params = {}): Observable<FeatureStats> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/features?${urlparams}`);
  }

  getFeedbackStats(params = {}): Observable<any[]> {
    let urlparams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.statsApiUrl}/feedback?${urlparams}`);
  }

  getMetricsStats(params): Observable<MonthlyMetrics> {
    const urlParams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.metricsApiUrl}/?${urlParams}`);
  }

  getHistoricalStats(params: HistoricalStatsParams): Observable<HistoricalStats> {
    const urlParams = this.prepareUrlParams(params);
    return this.apiClient.get(`${this.metricsApiUrl}/historical?${urlParams}`);
  }

  /// Helper functions
  private prepareUrlParams(params: any): string {
    return Object.keys(params)
      .filter(k => params[k] !== null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
  }
}
