import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { AuthService } from "./auth.service"
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let user = this.authService.user;

        console.log(state.url);
        const urlPath = state.url.split("/");
        console.log(urlPath);
        console.log(urlPath[1]);


        if (user.user_role.id == 3 || (user.user_role.id == 4 && urlPath[1] == 'invoice')) {
            return Observable.of(true);
        } else if (user.user_role.id == 4) {
            this.router.navigate(["/hours"])
        }
        else if (user.user_role.id == 5) {
            return Observable.of(true);
        }
        return Observable.of(false);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state)
    }
}
