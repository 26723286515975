import { Injectable } from '@angular/core';
import {CanActivate,  ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router} from '@angular/router';
import { AuthService } from "./auth.service"
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuard implements CanActivate,CanActivateChild {

    constructor(private authService:AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
        console.log(state)
        return this.authService.validate().catch((err,observ)=>{
        	if(!state.url.startsWith("/login") && !state.url.startsWith("login"))
        		this.router.navigate(["/login"],{queryParams:{d:state.url}})
        	return Observable.throw(err)
        })
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
        return this.canActivate(route,state)
    }
}