import { Injectable } from "@angular/core";
import { ApiClient } from "./api-client";

@Injectable()
export class RatingService {
  matchApiUrl = "/admin/match";
  ratingApiUrl = "/admin/rating";

  constructor(public apiClient: ApiClient) {}

  createWorkerRating(match_id: number, ratingParams: any) {
    return this.apiClient.post(this.matchApiUrl + "/" + match_id + "/rating", ratingParams);
  }

  getWorkerRating(rating_id: number) {
    return this.apiClient.get(this.ratingApiUrl + "/" + rating_id);
  }

  updateWorkerRating(rating_id: number, ratingParams: any) {
    return this.apiClient.patch(this.ratingApiUrl + "/" + rating_id, ratingParams);
  }

  removeWorkerRating(rating_id: number) {
    return this.apiClient.delete(this.ratingApiUrl + "/" + rating_id);
  }
}
