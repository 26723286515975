import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AlertDialog } from "./alert-dialog"
import { ModalModule,BsModalService } from 'ngx-bootstrap/modal';
import { AlertDialogComponent } from "./alert-dialog.component"
@NgModule ({
  entryComponents: [
      AlertDialogComponent
  ],
  declarations: [
    //alert dialog
    AlertDialogComponent
  ],
  imports: [

    CommonModule,
    ModalModule.forRoot()
  ],
  exports: [
    
    //alert dialog
    AlertDialogComponent,
  ],
  providers: [AlertDialog,BsModalService]
})

export class AlertModule {  }