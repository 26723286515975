import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from "rxjs"
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: '[alert-dialog]',
  templateUrl: 'alert-dialog.component.html',
  styleUrls: ['alert-dialog.component.scss']
})
export class AlertDialogComponent  {
  title:string;
  message:string;
  buttons:any[];
  subtitle: string;
  onDismiss = new Subject<number>()

  constructor(public changeRef:ChangeDetectorRef){}

  init() {
    this.buttons = this.buttons || ["ok"]
    this.changeRef.detectChanges()

  }

  btnClick(index:number) {
    this.onDismiss.next(index)
  }

}
