import { ApiClient } from "./api-client";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Invoice, InvoiceData, InvoicePaginated, InvoiceParams, InvoiceSaveParams } from "../../models/invoice.model";
import { Worker } from "../../models/worker.model";
import { Project } from "../../models/project.model";

@Injectable()
export class InvoiceService {
  private adminApiUrl = "/admin";
  private adminApiUrlV2 = "/v2/admin";

  constructor(private apiClient: ApiClient) { }

  getInvoice(invoice_id: any): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrl}/invoice/${invoice_id}?task_segment=true`);
  }

  getInvoiceV2(invoice_id: any): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrlV2}/invoice/${invoice_id}`);
  }

  update(invoice_id: any, changes: any): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrl}/invoice/${invoice_id}`, changes);
  }

  updateInvoiceSection(invoice_id: any, changes: any): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrl}/invoice-section/${invoice_id}`, changes);
  }

  getContractorInvoices(contractor_id: any, week: number): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrl}/contractor/${contractor_id}/invoice?week=${week}`);
  }

  getCompanyInvoices(companyId: number, week: number): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrl}/company/${companyId}/weekly-invoices?week=${week}`);
  }

  exportCompanyInvoice(projectId: number, companyId: number, params: any): Observable<any> {
    params = params || {};
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(
      `${this.adminApiUrl}/company/${companyId}/project/${projectId}/invoice/export?${urlparams}`,
      { responseType: "text" }
    );
  }

  getCompanyWorkersHours(companyId: number, week: number): Observable<Worker[]> {
    return this.apiClient.get(`${this.adminApiUrl}/company/${companyId}/invoice/workers?week=${week}`);
  }

  getInvoiceProjects(companyId: number, week: number, provinceId: number): Observable<Project[]> {
    const urlParams = `?province_id=${provinceId}&week=${week}`;
    return this.apiClient.get(`${this.adminApiUrl}/company/${companyId}/projects/week-province${urlParams}`);
  }

  generateCompanyInvoice(companyId: number, params: InvoiceParams): Observable<Invoice> {
    params = params || <InvoiceParams>{};
    let urlParams = Object.keys(params)
      .filter(k => params[k] !== null && String(params[k]).length > 0)
      .map(k => {
        // treats arrays as spacial cases
        if (Array.isArray(params[k])) {
          let arrString = "";
          params[k].map((v, key) => {
            let joinSymbol = key + 1 === params[k].length ? "" : "&";
            arrString = arrString + k + "[]=" + v + joinSymbol;
          });
          return arrString;
        } else {
          return k + "=" + params[k];
        }
      })
      .join("&");

    return this.apiClient.get(`${this.adminApiUrl}/company/${companyId}/invoice/generate?${urlParams}`);
  }

  saveInvoice(companyId: number, invoiceParams: InvoiceSaveParams): Observable<Invoice> {
    return this.apiClient.post(`${this.adminApiUrl}/invoice`, invoiceParams);
  }

  getInvoices(params: any): Observable<InvoicePaginated> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.adminApiUrl}/invoice?${urlparams}`);
  }

  deleteInvoiceV1(invoiceId: number): Observable<any> {
    return this.apiClient.delete(`${this.adminApiUrl}/invoice/${invoiceId}`);
  }

  deleteInvoiceV2(invoiceId: number): Observable<any> {
    return this.apiClient.delete(`${this.adminApiUrlV2}/invoice/${invoiceId}`);
  }

  exportInvoicePDF(invoiceId: number): Observable<any> {
    return this.apiClient.post(`${this.adminApiUrl}/invoice/${invoiceId}/export`, {}, { responseType: "text" });
  }

  exportInvoicePDFs(invoiceIds: any): Observable<any> {
    return this.apiClient.post(`${this.adminApiUrlV2}/zip/invoices`, invoiceIds);
  }

  downloadInvoicePDFsZip(path: string): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrlV2}/zip/invoices/download?zipped_path=${path}`);
  }

  sendInvoiceEmail(invoiceId: number, email: string[]): Observable<any> {
    const emailParams = email && email.length > 0 ? { emails: email } : null;
    return this.apiClient.post(`${this.adminApiUrl}/invoice/${invoiceId}/send`, emailParams);
  }

  changeInvoiceStatus(invoiceId: number, params: any): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrl}/invoice/${invoiceId}`, params);
  }

  changeInvoiceMarkPaidV2(data): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrlV2}/invoice/mark-paid`, data);
  }

  changeInvoiceMarkUnpaidV2(data): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrlV2}/invoice/mark-unpaid`, data);
  }

  payInvoice(data): Observable<any> {
    return this.apiClient.post(`${this.adminApiUrlV2}/invoice/pay`, data);
  }

  updatePO(invoiceId: number, data): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrlV2}/invoice/${invoiceId}/purchase-order-number`, data);
  }

  getInvoiceDrafts(params: any): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.adminApiUrl}/invoice/drafts?${urlparams}`);
  }

  saveInvoiceDraft(invoices: InvoiceData[]): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrlV2}/invoice/draft/save`, { 'invoices': invoices });
  }

  generateInvoiceDrafts(data): Observable<any> {
    return this.apiClient.post(`${this.adminApiUrlV2}/invoice/draft/generate`, data);
    // return this.apiClient.post(`${this.adminApiUrlV2}/invoice/draft/generate-weekly`, data);
  }

  getInvoicePreview(data): Observable<any> {
    return this.apiClient.post('/v2/admin/invoice/preview', data);
  }

  saveInvoiceV2(data): Observable<any> {
    return this.apiClient.post('/v2/admin/invoice', data);
  }

  sendInvoiceV2(data): Observable<any> {
    return this.apiClient.post(`${this.adminApiUrlV2}/invoice/send`, data);
  }

  getTaxTypes(): Observable<any> {
    return this.apiClient.get('/v2/c/tax-types');
  }

  createInvoiceLineItemCharges(params: any): Observable<any> {
    return this.apiClient.post('/v2/admin/invoice-line-item-charge', params);
  }

  deleteInvoiceLineItemCharge(id: number): Observable<any> {
    return this.apiClient.delete("/v2/admin/invoice-line-item-charge/" + id);
  }

  getInvoiceLineItemCharges(params: any): Observable<any> {

    params = params;

    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`/v2/admin/invoice-line-item-charge?${urlparams}`);
  }
}
