import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";

import { ApiClient } from "./api-client";
import { Router } from "@angular/router";
import { Match } from "../../models/match.model";
import { Reference } from "../../models/constant.objects";

@Injectable()
export class MatchService {
  matchApiUrl = "/admin/match";
  requestApiUrl = "/admin/request";
  requestSegmentApiUrl = "/admin/request-segment";
  noShowApiUrl = "/admin/no-show";
  requestExtensionApiUrl = "/v2/admin/request-extension";

  constructor(public apiClient: ApiClient, public router: Router) {}

  placeWorker(params: any): Observable<Match> {
    return this.apiClient.post(`${this.matchApiUrl}`, params);
  }

  autoMatch(request_id: number): Observable<Match> {
    return this.apiClient.get(`${this.requestApiUrl}/${request_id}/automatch`);
  }

  autoMatchByTier(request_id: number, tier_id: number): Observable<Match> {
    return this.apiClient.get(`${this.requestApiUrl}/${request_id}/automatch?tier=${tier_id}`);
  }

  workerAcceptsJob(match_id: number, notification: boolean): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/accept-worker`, { notification: notification });
  }

  workerDeclinesJob(match_id: number): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/decline-worker`, null);
  }

  contractorAcceptsWorker(match_id: number, notification: boolean): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/accept-contractor`, { notification: notification });
  }

  contractorDeclinesWorker(match_id: number): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/decline-contractor`, null);
  }

  setToFound(match_id: number): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/found`, null);
  }

  getNoShow(no_show_id) {
    return this.apiClient.get(`${this.noShowApiUrl}/${no_show_id}`);
  }

  recordNoShow(request_segment_id, params) {
    return this.apiClient.post(`${this.requestSegmentApiUrl}/${request_segment_id}/no-show`, params);
  }

  deleteNoShow(request_segment_id) {
    return this.apiClient.delete(`${this.requestSegmentApiUrl}/${request_segment_id}/no-show`);
  }

  contractorRemovesAcceptedWorker(match_id: number): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/remove-contractor`, null);
  }

  acceptedWorkerCancels(match_id: number, unavailableType: any): Observable<Match> {
    return this.apiClient.patch(`${this.matchApiUrl}/${match_id}/quit-worker`, { cancellation_type_id: unavailableType.id, description: unavailableType.description });
  }

  getReview(match_id: number): Observable<Match> {
    return this.apiClient.get(`${this.matchApiUrl}/${match_id}/review`);
  }

  recordReview(match_id: number, review: Reference): Observable<Match> {
    return this.apiClient.post(`${this.matchApiUrl}/${match_id}/review`, review);
  }

  sendJobTextLink(match_id: number): Observable<any> {
    return this.apiClient.post(`${this.matchApiUrl}/${match_id}/worker-link`, {});
  }

  sendExtensionRequest(params: any): Observable<any> {
    return this.apiClient.post(`${this.requestExtensionApiUrl}`, params);
  }
}
