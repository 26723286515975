import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';

import { ApiClient } from './api-client';
import {Router} from "@angular/router";
import {User} from "../../models/user";

@Injectable()
export class AuthService {
  authApiUrl = '/auth';
  userApiUrl = '/user';

  user: User;

  constructor(public apiClient: ApiClient, public router: Router) {
  }

  authenticate(email: string, password: string): Observable<string> {
      return this.apiClient.post(this.authApiUrl + '/admin', {email, password})
          .map(res => {
              let tokenObject = <any>(res);
              let token = tokenObject.token;
              console.log(token);
              localStorage.setItem('token', token);
              //this.eventsService.sendLoggedInEvent();
              return token;
          })
  }

  validate(): Observable<boolean> {
      return this.apiClient.get(this.userApiUrl)
          .map(res => {
              this.user = <User>(res);
              //this.eventsService.sendLogoutEvent();
              return true;
          }, () => {
              return false;
          })
  }

  logout(): Observable<boolean> {
      return this.apiClient.get(this.authApiUrl + '/logout')
          .map(res => {
              localStorage.removeItem('token');
              console.log('removing token');
              //this.eventsService.sendLogoutEvent();
              this.router.navigate(['/login']);
              return true;
          })
  }

}
