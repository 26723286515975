import { ApiClient } from "./api-client";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { PurchaseOrderNumber } from "../../models/purchase-order-number.model";

@Injectable()
export class PurchaseOrderNumberService {
  private adminApiUrl = "/v2/admin";

  constructor(private apiClient: ApiClient) { }

  update(id: any, changes: any): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrl}/purchase-order-number/${id}`, changes);
  }

  save(purchase_order_number: PurchaseOrderNumber): Observable<PurchaseOrderNumber> {
    return this.apiClient.post(`${this.adminApiUrl}/purchase-order-number`, purchase_order_number);
  }

  delete(id: number): Observable<any> {
    return this.apiClient.delete(`${this.adminApiUrl}/purchase-order-number/${id}`);
  }

  get(projectId: number, data: any): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrl}/project/${projectId}/purchase-order-number?start_date=${data.start_date}&end_date=${data.end_date}`);
  }
}
