import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";

import { ApiClient } from "./api-client";
import { Router } from "@angular/router";

import { User, UserBasicInfo, TradeExpPriceParams } from "../../models/user";

@Injectable()
export class UserService {
  adminV2 = '/v2/admin';
  userApiUrl = "/admin/user";
  adminApiUrl = "/admin/configurations";

  constructor(public apiClient: ApiClient, public router: Router) { }

  getAdmins(): Observable<User[]> {
    return this.apiClient.get(`${this.userApiUrl}/admins`);
  }

  editPassword(id: number, info: { password: string; confirm_password: string }): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/password`, info);
  }

  editBasicUserInfo(workerId: number, info: UserBasicInfo): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${workerId}`, info);
  }

  assignAdmin(id: number): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/role/assign-admin`, {});
  }

  removeAdmin(id: number): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/role/remove-admin`, {});
  }

  toggleNotifs(id: number, notifications: any): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}`, notifications);
  }

  updateIntercom(id: number): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/intercom`, {});
  }

  updatePaymentRails(id: number): Observable<any> {
    return this.apiClient.patch(`${this.userApiUrl}/${id}/update-payment-rails`, {});
  }

  getAllAdminConfig(): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrl}`);
  }

  getSingleAdminConfig(key: string): Observable<any> {
    return this.apiClient.get(`${this.adminApiUrl}/${key}`);
  }

  toggleSingleAdminConfig(key: string): Observable<any> {
    return this.apiClient.patch(`${this.adminApiUrl}/${key}/toggle`, {});
  }

  // get the Trade Experience Price

  getTradeExperiencePrice(params: TradeExpPriceParams): Observable<any> {
    const urlParams = this.prepareParams(params);

    return this.apiClient.get('/v2/location-trade-pricing?' + urlParams);
  }

  // getTradeExpPrice(params: TradeExpPriceParams): Observable<any> {
  //   const urlParams = this.prepareParams(params);

  //   return this.apiClient.get(this.userApiUrl + '/texas-pricing/prices?' + urlParams);
  // }

  // getTradeExpPriceCA(params: TradeExpPriceParams): Observable<any> {
  //   const urlParams = this.prepareParams(params);

  //   return this.apiClient.get('/c/location-trade-pricing?' + urlParams);
  // }

  updateRequestsPrice(editPricesParams: any) {
    return this.apiClient.patch(`${this.adminV2}/request/edit-multiple-prices`, editPricesParams);
  }

  //metabe iFrame URL for agency users
  getMetabaseiFrameUrl(): Observable<any> {
    return this.apiClient.get(`/v2/agency/metabase-iframe-url`);
  }

  /// Helpers functions
  private prepareParams(params: any): string {
    return Object.keys(params)
      .filter(k => params[k] !== null && String(params[k]).length > 0)
      .map(k => {
        return k + '=' + params[k];
      })
      .join('&');
  }
}
