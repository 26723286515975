import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { SharedService } from "./shared/services/shared.service";
import { routing } from "./app.routing";

import { TimeLineService } from "./shared/services/timeline.service";
import { AuthService } from "./shared/services/auth.service";
import { ApiClient } from "./shared/services/api-client";
import { ApiInterceptor } from "./shared/services/api-interceptor";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from "./shared/services/auth-guard.service";
import { WorkerService } from "./shared/services/worker.service";
import { ConstantsService } from "./shared/services/constants.service";
import { ContractorService } from "./shared/services/contractor.service";
import { RequestService } from "./shared/services/request.service";
import { MatchService } from "./shared/services/match.service";
import { ProjectService } from "./shared/services/project.service";
import { QuoteService } from "./shared/services/quote.service";
import { HourService } from "./shared/services/hour.service";
import { TagService } from "./shared/services/tags.service";
import { AgmCoreModule } from "@agm/core";
import { AlertModule } from "./shared/components/alert-dialog/alert-dialog.module";
import { AlertDialog } from "./shared/components/alert-dialog/alert-dialog";
import { FormsModule } from "@angular/forms";
import { ImageService } from "./shared/services/image.service";
import { UserService } from "./shared/services/user.service";
import { StatsService } from "./shared/services/stats.service";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressHttpModule } from "@ngx-progressbar/http";
import { CityService } from "./shared/services/city.service";
import { AppInstallsService } from "./shared/services/app-installs.service";
import { InvoiceService } from "./shared/services/invoice.service";
import { CompanyService } from "./shared/services/company.service";
import { RoleGuard } from "./shared/services/role-guard.service";
import { PaymentService } from "./shared/services/payment.service";
import { ReferralService } from "./shared/services/referral.service";
import { ExportService } from "./shared/services/export.service";
import { RatingService } from "./shared/services/rating.service";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { DaysSinceStartPipe } from './shared/pipes/days-since-start.pipe';
import {PurchaseOrderNumberService} from "./shared/services/purchase-order-number.service";


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    routing,
    AlertModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCwwY1H0spXG5Ue5JhKDuEvaj55ORbuwKM",

      libraries: ["places", "visualization"]
    }),
    NgProgressModule,
    ModalModule.forRoot(),
    NgProgressHttpModule
  ],
  declarations: [AppComponent],
  providers: [
    DaysSinceStartPipe,
    SharedService,
    AuthService,
    ApiClient,
    AuthGuard,
    RoleGuard,
    ConstantsService,
    ContractorService,
    HourService,
    RequestService,
    MatchService,
    ProjectService,
    QuoteService,
    WorkerService,
    UserService,
    ConstantsService,
    TimeLineService,
    ImageService,
    StatsService,
    CityService,
    CompanyService,
    AppInstallsService,
    InvoiceService,
    PaymentService,
    ExportService,
    RatingService,
    AlertDialog,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    ReferralService,
    TagService,
    PurchaseOrderNumberService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
