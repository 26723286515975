import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";

import { ApiClient } from "./api-client";
import { Router } from "@angular/router";
//import {} from "../../models/constant.model";

@Injectable()
export class TimeLineService {
  userApiUrl = "/admin/user";
  companyApiUrl = "/admin/company";
  requestGroupApiUrl = "/admin/request-group";
  matchApiUrl = "/admin/match";

  constructor(public apiClient: ApiClient, public router: Router) {}

  getTimelineEvents(user_id: number, params: any): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.userApiUrl}/${user_id}/timeline-event?${urlparams}`);
  }

  getCompanyTimelineEvents(companyId: number, params: any): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.companyApiUrl}/${companyId}/timeline-event?${urlparams}`);
  }

  getRequestGroupTimelineEvents(request_group_id: number, params: any): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.requestGroupApiUrl}/${request_group_id}/timeline-event?${urlparams}`);
  }

  getMatchTimelineEvents(match_id: number, params: any): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;
    let urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");
    return this.apiClient.get(`${this.matchApiUrl}/${match_id}/timeline-event?${urlparams}`);
  }

  createTimelineEvent(user_id: number, event_type: number, event_body: string): Observable<any> {
    let params = {
      code_timeline_event_type_id: event_type,
      body: event_body
    };
    return this.apiClient.post(`${this.userApiUrl}/${user_id}/timeline-event`, params);
  }

  createRequestGroupTimelineEvent(user_id: number, event_type: number, event_body: string): Observable<any> {
    let params = {
      code_timeline_event_type_id: event_type,
      body: event_body
    };
    return this.apiClient.post(`${this.requestGroupApiUrl}/${user_id}/timeline-event`, params);
  }

  createMatchTimelineEvent(matchId: number, event_type: number, event_body: string): Observable<any> {
    let params = {
      code_timeline_event_type_id: event_type,
      body: event_body
    };
    return this.apiClient.post(`${this.matchApiUrl}/${matchId}/timeline-event`, params);
  }

  deleteTimelineEvent(eventId: number): Observable<any> {
    return this.apiClient.delete(`/admin/timeline-event/${eventId}`);
  }
}
