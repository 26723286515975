
<div class="modal-header">
  <p class="subtitle" *ngIf="subtitle">{{ subtitle }}</p>
  <h5 class="modal-title pull-left">{{title}}</h5>
</div>
<div class="modal-body" [innerHTML]="message">
  <!-- {{message}} -->
</div>
<div class="modal-footer">

  <button type="button" class="btn btn-link" *ngFor="let b of buttons;let i = index" (click)="btnClick(i)">{{b}}</button>
</div>