import { RequestReview, RequestSegment } from "./request.model";
import { Company } from "./company.model";
export interface ConstantModalInterface {
  getAPIPath: Function;

  getName: Function;

  getFields: Function;

  getEditFields: Function;
}

export class ConstantModal {
  constructor() {}

  static getAPIPath() {
    return "null";
  }

  static getName() {
    return "modal name";
  }

  static getFields() {
    return ["id", "description"];
  }

  static getEditFields() {
    return ["description"];
  }
}

export class User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: number;
  profile_picture_link: string;
  bio: string;
  date_of_birth: Date;
  terms: boolean;
  reviews: RequestReview[];
  company_name: string;
  company: Company;
  confirmation_code: number;
  referred_by: any;
  worker_status: WorkerStatus;
  rating: string;
  distance: number;
  last_login_at: any;
  new: boolean;
  first_worked_at: any;
  tools?: Tool[];
  worker_tools?: any[];
  title?: string;
  address?: Address;
  user_role?: UserRole;
  request_segments?: RequestSegment[];
  worker_location_status: number;
  code_worker_location_status_id: number;
  score: number;
  worker_quality_score?: {
    id: number;
    score: number;
    user_id: number;
  };
  intercom: any;

  constructor(
    id: number = null,
    first_name: string = null,
    last_name: string = null,
    email: string = null,
    phone_number: number = null,
    profile_picture_link: string = null,
    bio: string = null,
    date_of_birth: Date = null,
    terms: boolean = null,
    company_name: string = null,
    confirmation_code: number = null,
    referred_by: any = null
  ) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone_number = phone_number;
    this.profile_picture_link = profile_picture_link;
    this.bio = bio;
    this.date_of_birth = date_of_birth;
    this.terms = terms;
    this.company_name = company_name;
    this.confirmation_code = confirmation_code;
    this.referred_by = referred_by;
  }
}

export interface Location {
  lat: number;
  lng: number;
}

export class Address {
  description: string;
  unit_number?: any;
  location: Location;
  street_number: string;
  street_name: string;
  city: string;
  province: string;
  country: string;
  postal_code: string;
  img_url?: string;
}

export class Weekday extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "WeekDay";
  }

  static getAPIPath() {
    return "/weekdays";
  }
}

export class BreakTime extends ConstantModal {
  id: number;
  description: string;
  number?: any;

  static getName() {
    return "Break times";
  }

  static getAPIPath() {
    return "/break-times";
  }
}

export class Experience extends ConstantModal {
  id: number;
  description: string;
  short_description: string;
  number?: string;

  static getName() {
    return "Experience";
  }

  static getAPIPath() {
    return "/experiences";
  }

  static getFields() {
    let fields = super.getFields();
    fields.push("short_description");
    return fields;
  }

  static getEditFields() {
    let fields = super.getEditFields();
    fields.push("short_description");
    return fields;
  }
}

export class Location {
  lat: number;
  lng: number;
}

export class Tool extends ConstantModal {
  id: number;
  description: string;
  is_certifiable: boolean;
  text?: string;

  static getName() {
    return "Tool";
  }

  static getAPIPath() {
    return "/tools";
  }
}

export interface ToolsParams {
  project_id?: number;
  user_id?: number;
}

export class Trade extends ConstantModal {
  id: number;
  description: string;
  contractor_visible?: boolean;

  static getName() {
    return "Trade";
  }

  static getAPIPath() {
    return "/trades";
  }
}

export class TradeExperience {
  id: number;
  trade: Trade;
  experience: Experience;
  number: number;
  contractor_visible?: boolean;

  constructor(id: number, trade: Trade, experience: Experience, number: number) {
    this.id = id;
    this.trade = trade;
    this.experience = experience;
    this.number = number;
  }
}

export class Reference {
  id: number;
  user_id: number;
  company_name: string;

  attitude: number;
  comments: string;
  contacted: boolean;
  effort: number;
  follows_instructions: number;
  job_title: string;
  punctuality: number;
  skill_match: number;
  works_well_with_others: number;
  quality_of_work: number;
  efficiency: number;
  attendance: number;
  professionalism: number;

  supervisor_email?: string;
  supervisor_name?: string;
  supervisor_phone_number?: number;
  supervisor_title?: string;

  deleted_at: any;
  // constructor(
  // 	id: number,
  // 	company_name: string,
  // 	supervisor_name: string,
  // 	supervisor_phone_number: number,
  // 	job_title: string,
  // 	contacted: boolean,
  // 	effort:number,
  // 	attitude: number,
  // 	punctuality: number,
  // 	skill_match: number,
  // 	follows_instructions: number,
  // 	works_well_with_others:number,
  // 	comments:string
  // ) {
  // 	this.id = id;
  // 	this.company_name = company_name;
  // 	this.supervisor_name = supervisor_name;
  // 	this.supervisor_phone_number = supervisor_phone_number;
  // 	this.job_title = job_title;
  // 	this.contacted = contacted
  // 	this.effort = effort;
  // 	this.attitude = attitude;
  // 	this.punctuality = punctuality;
  // 	this.skill_match = skill_match;
  // 	this.follows_instructions = follows_instructions;
  // 	this.works_well_with_others = works_well_with_others;
  // 	this.comments = comments
  // }
}

export class UserRole extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "User role";
  }

  static getAPIPath() {
    return "/user-roles";
  }
}

export class WorkerStatus extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Worker Status";
  }

  static getAPIPath() {
    return "/worker-status";
  }
}

export class RequestStatus extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Request Status";
  }

  static getAPIPath() {
    return "/request-status";
  }
}

export class RequestSource extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Request Source";
  }

  static getAPIPath() {
    return "/request-source";
  }
}

export class ProjectStatus extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Project Status";
  }

  static getAPIPath() {
    return "/project-status";
  }
}

export class MatchStatus extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Match Status";
  }

  static getAPIPath() {
    return "/match-status";
  }
}

export class Term {
  id: number;
  description: string;
}

export class ReferralSource extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Referall Source";
  }

  static getAPIPath() {
    return "/referral-sources?contractor_visible=1";
  }
}

export class Province extends ConstantModal {
  id: number;
  description: string;
  abbreviation: string;

  static getName() {
    return "Province";
  }

  static getAPIPath() {
    return "/provinces";
  }

  static getFields() {
    let fields = super.getFields();
    fields.push("abbreviation");
    return fields;
  }

  static getEditFields() {
    let fields = super.getEditFields();
    fields.push("abbreviation");
    return fields;
  }
}

export class Country extends ConstantModal {
  id: number;
  description: string;
  abbreviation: string;

  static getName() {
    return "Country";
  }

  static getAPIPath() {
    return "/countries";
  }

  static getFields() {
    let fields = super.getFields();
    fields.push("abbreviation");
    return fields;
  }

  static getEditFields() {
    let fields = super.getEditFields();
    fields.push("abbreviation");
    return fields;
  }
}

export class HourStatus extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "Hour Status";
  }

  static getAPIPath() {
    return "/hour-status";
  }
}

export class Overtime extends ConstantModal {
  id: number;
  description: string;
  number: number;

  static getName() {
    return "Over time";
  }

  static getAPIPath() {
    return "/overtimes";
  }

  static getFields() {
    let fields = super.getFields();
    fields.push("description");
    return fields;
  }

  static getEditFields() {
    let fields = super.getEditFields();
    fields.push("description");
    return fields;
  }
}

export class City extends ConstantModal {
  id: number;
  description: string;
  province: Province;
  country: Country;
  code_province_id: number;
  selected?: boolean;

  static getName() {
    return "City";
  }

  static getAPIPath() {
    return "/cities";
  }
}

export class FeedbackAnswer extends ConstantModal {
  id: number;
  description: string;

  static getName() {
    return "FeedbackAnswer";
  }
}

export class TierStatus extends ConstantModal {
  id: number;
  description: string;
  rank: number;

  static getName() {
    return "Tier Status";
  }

  static getAPIPath() {
    return "/tier-status";
  }
}
