import { ApiClient } from "./api-client";
import { MapStats } from "../../models/map-stats";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

@Injectable()
export class AppInstallsService {
  private appInstallsApiUrl = "/admin/sales-leads";

  constructor(private apiClient: ApiClient) {}

  sendMessage(sales_lead): Observable<any> {
    return this.apiClient.post(`${this.appInstallsApiUrl}/send-app-link`, sales_lead);
  }

  getAppInstalls(params: any): Observable<any> {
    params = params || {};
    if (!("page" in params)) params.page = 1;

    const urlparams = Object.keys(params)
      .filter(k => params[k] != null && String(params[k]).length > 0)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    return this.apiClient.get(`${this.appInstallsApiUrl}?${urlparams}`);
  }
}
