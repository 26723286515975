import { Component } from '@angular/core';
import { Observable }           from 'rxjs/Observable';
import { CanActivateChild, NavigationEnd , CanActivate ,ActivatedRouteSnapshot, RouterStateSnapshot, Router }  from '@angular/router';
import { filter } from 'rxjs/operators/filter';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  title = 'app';
  modalInstances:BsModalService[] = [];

  constructor(private router:Router) {
    let me = this;
    let initial = BsModalService.prototype.show
    BsModalService.prototype.show =  <any>function(content,config) {
      if(!me.modalInstances.find(m=>m==this))
      	me.modalInstances.push(this)
      
      return initial.call(this,content,config)
    }

    this.router.events.pipe(
       filter(t=>t instanceof NavigationEnd)
      )
    .subscribe(event=>{
      this.modalInstances.forEach(bsModal=>{
      	  for(let i=0; i<bsModal.getModalsCount();i++)
            bsModal.hide(i+1)
            
      })

      document.body.classList.remove("modal-open");
      
      
    })

  }

}
