import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { CompanyTag } from "../../models/company.model";
import { ApiClient } from "./api-client";
import { Observable } from "rxjs";

@Injectable()
export class TagService {
  tagApiUrl = "/tag";
  companyApiUrl = "/admin/company";

  constructor(private apiClient: ApiClient) {}

  getTags(): Observable<CompanyTag[]> {
    return this.apiClient.get(`${this.tagApiUrl}?company_visible=true`).map(res => res || {});
  }

  getCompanyTags(companyId: number): Observable<CompanyTag[]> {
    return this.apiClient.get(`${this.companyApiUrl}/${companyId}/tag`).map(res => res || {});
  }

  saveCompanyTags(companyId: number, tags: CompanyTag[]): Observable<CompanyTag[]> {
    return this.apiClient.post(`${this.companyApiUrl}/${companyId}/tag`, { tags: tags }).map(res => res || {});
  }
}
